import { Box, Link, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { COLOURS } from '../Themes';

function ResponseFailedError() {
  const navigate = useNavigate();

  const linkOnClick = () => {
    navigate(0); // this will refresh page
  };

  return (
    <Box
      alignItems="center"
      className="response-failed-error-container"
      display="flex"
      justifyContent="center"
      margin="1rem"
      marginTop="1rem"
    >
      <Typography color={COLOURS.failure} fontSize="1.25rem">
        There was an error generating your response.{' '}
        <Link color="inherit" to="/" onClick={linkOnClick}>
          Try refreshing
        </Link>
        .
      </Typography>
    </Box>
  );
}

export default ResponseFailedError;
