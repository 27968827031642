import React, { createContext, useContext, useMemo } from 'react';

const BrandingContext = createContext();

const BRANDING_DEFAULT = {
  texts: {
    disclaimer:
      'Please note: Answers provided by the EPulseAl Co-Pilot are generated using machine learning algorithms and may not always return accurate results.',
    init_prompt: 'Hi there! I am your EPulseAl Co-Pilot.',
    init_prompt_2: 'How can I help you?',
    arrow_down: 'To get started you can enter your question below',
    question_disclaimer:
      'Please note: Questions provided by EPulseAI Data Co-Pilot are generated using machine learning algorithms and may not always return accurate results.',
  },
  shouldShowHeader: true,
  shouldShowLandingInfo: true,
};

const BRANDING_UPHEALTH = {
  texts: {
    disclaimer:
      'Please note: Answers are generated using machine learning algorithms and may not always return accurate results.',
    init_prompt: 'Hi there! I am your Data Assistant',
    init_prompt_2: 'How can I help you?',
    arrow_down: 'To get started you can enter your question below',
    question_disclaimer:
      'Please note: Questions were generated using machine learning algorithms and may not always return accurate results.',
  },
  shouldShowHeader: false,
  shouldShowLandingInfo: false,
};

const BRANDING_BY_NAME = {
  uphealth: BRANDING_UPHEALTH,
};

export function BrandingProvider({ children }) {
  const branding = useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const brandName = searchParams.get('brand') || '';
    return BRANDING_BY_NAME[brandName.toLowerCase()] || BRANDING_DEFAULT;
  }, []);

  // Memoize the context value to prevent unnecessary re-renders
  const memoizedValue = useMemo(() => ({ branding }), [branding]);

  return (
    <BrandingContext.Provider value={memoizedValue}>
      {children}
    </BrandingContext.Provider>
  );
}

export function useBranding() {
  return useContext(BrandingContext);
}
