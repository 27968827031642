import './DataTable.css';

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';

function DataTable({ data, visible }) {
  return (
    visible && (
      <Box className="table-container">
        <Box className="scrollable-table">
          <Table className="table">
            <TableHead>
              <TableRow>
                {Object.keys(data[0]).map((column, index) => (
                  <TableCell key={index}>{column}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {Object.values(row).map((cell, cellIndex) => (
                    <TableCell key={cellIndex}>{cell}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
    )
  );
}

export default DataTable;
