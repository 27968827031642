import {
  Box,
  Button,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import { memo } from 'react';
import FileUpload from 'react-material-file-upload';

import useLoggedInUser from '../Hooks/useLoggedInUser';
import { COLOURS } from '../Themes/style';

const CustomDataSetUpload = memo(
  ({
    files,
    handleFileChange,
    handleKnowledgeBaseChange,
    handleUpload,
    error,
    sx,
  }) => {
    const { loggedInUser } = useLoggedInUser();

    return (
      <Stack
        alignItems="center"
        display="flex"
        justifyContent="center"
        padding={1}
        sx={{ gap: '1rem' }}
        width="100%"
      >
        <FileUpload
          accept=".csv, .sqlite"
          buttonText="Select data file"
          maxSize={25 * 1024 * 1024}
          sx={{
            width: '50%',
            '* > .MuiTypography-root': {
              fontFamily: 'Barlow',
            },
            '* > .MuiSvgIcon-root': {
              color: COLOURS.primary,
            },
            '* > .MuiFormHelperText-root': {
              color: 'red',
            },
            ...sx,
          }}
          title="Select a file to upload to EPulseAI Data Co-Pilot for analysis."
          value={files}
          onChange={handleFileChange}
        />
        {error !== null && (
          <Box>
            <FormHelperText error={!!error}>
              We encountered an error! {error}.
            </FormHelperText>
          </Box>
        )}
        {loggedInUser.knowledgeBases?.length > 0 && (
          <>
            <InputLabel>Select a Knowledge Base to apply</InputLabel>
            <Select
              defaultValue=""
              displayEmpty
              onChange={handleKnowledgeBaseChange}
            >
              <MenuItem value="" selected>
                None
              </MenuItem>
              {loggedInUser.knowledgeBases.map((data, index) => (
                <MenuItem key={index} value={data.id}>
                  {data.name}
                </MenuItem>
              ))}
            </Select>
          </>
        )}
        <Box>
          <Button
            aria-disabled={!!error || files.length === 0}
            aria-label="Upload file"
            disabled={!!error || files.length === 0}
            type="submit"
            variant="contained"
            onClick={handleUpload}
          >
            Upload
          </Button>
        </Box>
      </Stack>
    );
  },
);
CustomDataSetUpload.displayName = 'CustomDataSetUpload';

export default CustomDataSetUpload;
