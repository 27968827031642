import { AppBar, Link, Toolbar } from '@mui/material';
import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import EmidsLogo from '../Assets/Images/EmidsHealthConnect.svg';
import Logo from '../Assets/Images/EPulseAI_Data_Co-Pilot_Logo.svg';

export default function Header() {
  const navigate = useNavigate();
  const handleLogoClick = () => {
    navigate(0);
  };

  return (
    <AppBar color="transparent" position="relative">
      <Toolbar
        sx={{
          boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.25)',
          zIndex: 10,
        }}
      >
        <Link
          component={RouterLink}
          to="/"
          onClick={handleLogoClick} // TODO: DO NOT USE THIS IN THE FUTURE!
        >
          <img
            alt="EPulseAI Data-Co-Pilot Logo"
            src={Logo}
            style={{
              maxWidth: '100%',
            }}
          />
        </Link>
        <img
          alt="Emids Logo"
          src={EmidsLogo}
          style={{
            marginLeft: 'auto',
            maxWidth: '100%',
          }}
        />
      </Toolbar>
    </AppBar>
  );
}
