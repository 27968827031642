import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { useCallback, useState } from 'react';

import EPulseLogo from '../Assets/Images/EPulseAI_Data_Co-Pilot_Logo.svg';
import LandingPage from '../Assets/Images/EpulseAI_landing_robot.png';
import PageLayout from '../Components/PageLayout';
import API_URL from '../config';
import { useBranding } from '../Utils/BrandingContext';

function Login({ setLoggedInUser }) {
  const { branding } = useBranding();

  const [formData, setFormData] = useState({
    email: localStorage.getItem('email') || '',
    password: localStorage.getItem('password') || '',
    rememberMe: localStorage.getItem('rememberMe') === 'true' || false,
  });

  const resetState = useCallback(() => {
    setFormData({
      email: '',
      password: '',
      rememberMe: false,
    });
  }, []);

  const handleSubmit = useCallback(
    async event => {
      event.preventDefault();
      const { email, password, rememberMe } = formData;

      try {
        const response = await fetch(`${API_URL}/token`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email,
            password,
          }),
        });

        if (response.status === 200) {
          const responseJson = await response.json();
          setLoggedInUser({
            profilePicUrl: responseJson.profile_pic_url,
            isDev: responseJson.is_dev,
            canUploadCustomDataSet: responseJson.can_upload_custom_data_set,
            defaultDataSet: responseJson.default_data_set,
            accessToken: responseJson.access_token,
            knowledgeBases: responseJson.knowledge_bases,
          });

          localStorage.setItem('rememberMe', rememberMe);

          if (rememberMe === true) {
            localStorage.setItem('email', email);
            localStorage.setItem('password', password);
          }
        }
      } catch (e) {
        console.error('Unable to log in, error: ', e);
      } finally {
        resetState();
      }
    },
    [formData, resetState, setLoggedInUser],
  );

  const updateField = useCallback(
    e => {
      setFormData({
        ...formData,
        [e.target.name]:
          e.target.type === 'checkbox' ? e.target.checked : e.target.value,
      });
    },
    [formData],
  );

  return (
    <PageLayout>
      <Stack alignItems="center" paddingX="17.5rem">
        <Stack alignItems="center" mt={2} width="37.5rem">
          {branding.shouldShowLandingInfo && (
            <Box>
              <Box
                alignItems="center"
                display="flex"
                justifyContent="center"
                sx={{ gap: '1rem' }}
              >
                <Typography variant="h1">Welcome to the</Typography>
                <img
                  alt="EPulseAI Data Co-Pilot"
                  loading="lazy"
                  src={EPulseLogo}
                />
              </Box>
              <Box
                alignItems="center"
                display="flex"
                flex={1}
                justifyContent="center"
                my={2}
              >
                <img alt="landing page" loading="lazy" src={LandingPage} />
              </Box>
              <Typography fontStyle="italic" variant="body1">
                Meet EPulseAI Data Co-Pilot, your revolutionary AI chat bot for
                healthcare data exploration.
                <br />
                <br />
                In a world overflowing with healthcare information, EPulseAI
                Data Co-Pilot simplifies the process of analyzing vast datasets.
                It’s your partner in uncovering valuable insights quickly,
                whether you’re a researcher, administrator, or clinician.
                Experience the future of healthcare analytics with EPulseAI Data
                Co-Pilot.
              </Typography>
            </Box>
          )}
          <Typography variant="subtitle1">
            Use your credentials to sign in...
          </Typography>
          <Box
            component="form"
            display="flex"
            flexDirection="column"
            width="300px"
            // eslint-disable-next-line react/jsx-no-bind
            onSubmit={handleSubmit}
          >
            <TextField
              id="email"
              label="Email"
              margin="normal"
              name="email"
              value={formData.email}
              variant="outlined"
              onChange={updateField}
            />
            <TextField
              id="password"
              label="Password"
              margin="normal"
              name="password"
              type="password"
              value={formData.password}
              variant="outlined"
              onChange={updateField}
            />
            <FormGroup id="rememberMe">
              <FormControlLabel
                control={
                  <Switch
                    checked={formData.rememberMe}
                    color="primary"
                    name="rememberMe"
                    onChange={updateField}
                  />
                }
                label="Remember me"
              />
            </FormGroup>
            <Button
              aria-label="login"
              color="primary"
              type="submit"
              variant="contained"
            >
              Login
            </Button>
          </Box>
        </Stack>
      </Stack>
    </PageLayout>
  );
}

export default Login;
