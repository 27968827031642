import { Box, TextField } from '@mui/material';
import { motion } from 'framer-motion';
import React, { memo, useCallback } from 'react';
import { useScrollToBottom } from 'react-scroll-to-bottom';

import SubmitButton from '../SubmitButton';
import ChatInputFormContainer from './ChatInputFormContainer';

const MotionBox = motion(Box);
const MAX_INPUT_LENGTH = 10000;

const ChatInputForm = memo(({ input, setInput, handleSubmit, isLoading }) => {
  const scrollToBottom = useScrollToBottom();

  const submitData = useCallback(
    e => {
      e.preventDefault();
      handleSubmit(e);
      scrollToBottom({
        behavior: 'smooth',
      });
    },
    [handleSubmit, scrollToBottom],
  );

  const handleKeyPress = useCallback(
    e => {
      if (e.key !== 'Enter') return;
      if (isLoading || e.target.value.trim() === '') return;
      submitData(e);
    },
    [submitData, isLoading],
  );

  const onChangeHandler = useCallback(
    e => {
      setInput(e.target.value);
    },
    [setInput],
  );

  return (
    <ChatInputFormContainer>
      <MotionBox
        animate={{ opacity: 1 }}
        bgcolor="inherit"
        component="form" // turns the div into a form
        display="flex"
        initial={{ opacity: 0.5 }}
        marginTop="auto"
        paddingX="4rem"
        transition={{ duration: 0.1 }}
        width="100%"
        zIndex={1000}
      >
        <TextField
          disabled={isLoading}
          id="chat-input-textarea"
          InputProps={{
            endAdornment: (
              <SubmitButton isLoading={isLoading} onClick={submitData} />
            ),
            sx: {
              backgroundColor: 'white',
            },
            autoFocus: true,
          }}
          maxLength={MAX_INPUT_LENGTH}
          placeholder="Enter your question..."
          sx={{
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                borderColor: '#DADADA',
              },
            },
            '& .MuiOutlinedInput-root:hover': {
              '& > fieldset': {
                borderColor: '#DADADA',
              },
            },
          }}
          value={input}
          fullWidth
          multiline
          required
          onChange={onChangeHandler}
          onKeyDown={handleKeyPress}
        />
      </MotionBox>
    </ChatInputFormContainer>
  );
});

ChatInputForm.displayName = 'ChatInputForm';

export default ChatInputForm;
