import { Box, Button, Stack, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import React from 'react';

import EPulseLogo from '../Assets/Images/EPulseAI_Data_Co-Pilot_Logo.svg';
import useLoggedInUser from '../Hooks/useLoggedInUser';

const MotionStack = motion(Stack);

function Tips({ text, separater = '-' }) {
  const [beforeSeparater, ...afterSeparater] = text.split(separater);

  return (
    <Box display="inline-block">
      <Typography
        component="span"
        fontWeight={600}
        sx={{
          color: '#012A75',
        }}
      >
        {beforeSeparater} -{' '}
      </Typography>
      <Typography component="span" variant="body1">
        {afterSeparater}
      </Typography>
    </Box>
  );
}

function Hero({ setMode }) {
  const { loggedInUser } = useLoggedInUser();

  const setChatMode = () => {
    setMode('CHAT');
  };

  const setFileUploadMode = () => {
    setMode('FILE_UPLOAD');
  };

  return (
    <MotionStack
      alignItems="center"
      animate={{ opacity: 1 }}
      flexWrap="nowrap"
      height="100%"
      initial={{ opacity: 0 }}
      justifyContent="center"
      maxWidth="37.5rem"
      mx="auto"
      sx={{
        gap: '1.5rem',
      }}
      transition={{ duration: 0.5 }}
      width="100%"
    >
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        sx={{ gap: '1rem' }}
      >
        <Typography textAlign="center" variant="h1">
          Welcome to the
        </Typography>
        <img alt="EPulseAI Data Co-Pilot" loading="lazy" src={EPulseLogo} />
      </Box>
      <Typography variant="body1">
        {loggedInUser.defaultDataSet.description}
        <br />
        <br />
        Here&apos;s some tips to get you started:
      </Typography>
      <Box mx={1.5}>
        {loggedInUser.defaultDataSet.tips.map((tip, index) => (
          <Tips key={index} text={tip} />
        ))}
      </Box>

      <Button variant="contained" onClick={setChatMode}>
        Let&apos;s Chat
      </Button>
      <Typography variant="body1">
        Alternatively, to see how EPulseAI Data Co-Pilot can help you find
        insights with your own dataset you can upload your own .CSV data file to
        be analyzed and interrogate your own data.
      </Typography>
      <Button variant="contained" onClick={setFileUploadMode}>
        Upload My Data Set
      </Button>
    </MotionStack>
  );
}

export default Hero;
