import { Box, Container, Typography } from '@mui/material';
import React, { memo } from 'react';

import { CONTAINER_SIZE } from '../../Themes/style';
import { useBranding } from '../../Utils/BrandingContext';

const ChatInputFormContainer = memo(({ children }) => {
  const { branding } = useBranding();

  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        flexGrow={1}
        id="chat-input-container"
        justifyContent="center"
        marginBottom="0.5rem"
        marginTop="2rem"
        width="100%"
      >
        <Container
          maxWidth={CONTAINER_SIZE}
          sx={{ display: 'flex' }}
          disableGutters
        >
          {children}
        </Container>
      </Box>
      <Box marginBottom="0.5rem">
        <Typography
          color="#3E2FB2"
          fontStyle="italic"
          textAlign="center"
          variant="body1"
        >
          {branding.texts.disclaimer}
        </Typography>
      </Box>
    </>
  );
});
ChatInputFormContainer.displayName = 'ChatInputFormContainer';

export default ChatInputFormContainer;
