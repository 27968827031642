import { useState } from 'react';

function useLoggedInUser() {
  function getLoggedInUser() {
    const user = JSON.parse(sessionStorage.getItem('loggedInUser'));
    return user && user;
  }

  const [loggedInUser, setLoggedInUser] = useState(getLoggedInUser());

  function saveLoggedInUser(user) {
    sessionStorage.setItem('loggedInUser', JSON.stringify(user));
    setLoggedInUser(user);
  }

  return {
    setLoggedInUser: saveLoggedInUser,
    loggedInUser,
  };
}

export default useLoggedInUser;
