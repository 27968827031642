import { Button } from '@mui/material';
import React, { memo } from 'react';

import SubmitButtonSVG from './SubmitButtonSVG';

const SubmitButton = memo(({ isLoading, onClick }) => (
  <Button
    aria-label="submit"
    data-testid="submit-button"
    disabled={isLoading}
    onClick={onClick}
  >
    <SubmitButtonSVG />
  </Button>
));
SubmitButton.displayName = 'SubmitButton';

export default SubmitButton;
