import { CircularProgress } from '@mui/material';
import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import useLoggedInUser from './Hooks/useLoggedInUser';
import Login from './Pages/Login';
import MainPage from './Pages/MainPage/MainPage';

function App() {
  const { loggedInUser, setLoggedInUser } = useLoggedInUser();

  return (
    <Suspense
      fallback={
        <CircularProgress
          style={{
            margin: 0,
            position: 'absolute',
            top: '50%',
            left: '50%',
            color: '#b3befe',
          }}
        />
      }
    >
      {!loggedInUser ? (
        <Login setLoggedInUser={setLoggedInUser} />
      ) : (
        <Routes>
          <Route element={<MainPage loggedInUser={loggedInUser} />} path="/" />
        </Routes>
      )}
    </Suspense>
  );
}

export default App;
