import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    body1: {
      fontFamily: 'Barlow',
      fontWeight: 400,
      fontSize: '0.875rem',
      color: '#333',
      lineHeight: '1.375rem',
      fontStyle: 'normal',
    },
    h1: {
      fontFamily: 'Barlow',
      fontSize: '1.5rem',
      fontStyle: 'italic',
      fontWeight: 700,
      lineHeight: 'normal',
      color: '#0D0359',
    },
    subtitle1: {
      color: '#012A75',
      fontFamily: 'Barlow',
      fontSize: '1rem',
      fontStyle: 'italic',
      fontWeight: 600,
      lineHeight: '1.375rem',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundColor: '#533EED',
          '&:hover': {
            backgroundColor: '#6D52FF',
          },
        },
      },
    },
  },
});

export default theme;
