import './Chart.css';

import { Box } from '@mui/material';
import _ from 'lodash';
import _fp from 'lodash/fp';
import { useMemo } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

const COLORS = ['#0da37f', '#82ca9d', '#ffc658'];

// From data, the chart data keys are the keys of the first item
const extractDataKeys = _fp.flow([
  // multi-line
  _fp.get(0),
  _fp.keys,
]);

// The xLabel is the first label whose data are strings
const findLabelX = (data, dataKeys) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const dataKey of dataKeys) {
    const allItemsAreStrings = _fp.flow([
      _fp.map(dataKey),
      _fp.every(_fp.isString),
    ])(data);

    if (allItemsAreStrings) {
      return dataKey;
    }
  }

  // By default, the first label is LabelX
  return _.first(dataKeys);
};

function Chart({ data, visible }) {
  const allKeys = useMemo(() => extractDataKeys(data), [data]);
  const xDataKey = useMemo(() => findLabelX(data, allKeys), [data, allKeys]);
  const dataKeys = useMemo(
    () => _.difference(allKeys, [xDataKey]),
    [allKeys, xDataKey],
  );

  return (
    visible && (
      <Box className="chart-container">
        <BarChart data={data} height={400} width={600}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={xDataKey} />
          <YAxis />
          <Tooltip />
          <Legend />
          {dataKeys.map((key, index) => (
            <Bar
              dataKey={key}
              fill={COLORS[index % COLORS.length]}
              key={index}
            />
          ))}
        </BarChart>
      </Box>
    )
  );
}

export default Chart;
