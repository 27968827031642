/* eslint-disable import/prefer-default-export */

export function arrayToBulletPoints(strings) {
  if (!Array.isArray(strings)) {
    return ''; // Return an empty string if the input is not an array
  }

  const formattedList = strings.map(str => `\t• ${str}`).join('\n');

  return formattedList;
}
