// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-container {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  background-color: white;
  padding: 1rem;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  overflow-x: auto;
  width: -moz-fit-content;
  width: fit-content;
  overflow-x: auto;
}

.scrollable-table {
  /* width: 720px; */
  max-width: 100%;
}

.table {
  color: black;
  min-width: 400px;
}

.table th,
.table td {
  color: black;
}
`, "",{"version":3,"sources":["webpack://./src/Components/DataTable.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,sBAAsB;EACtB,uBAAuB;EACvB,aAAa;EACb,kBAAkB;EAClB,sCAAsC;EACtC,gBAAgB;EAChB,uBAAkB;EAAlB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;;EAEE,YAAY;AACd","sourcesContent":[".table-container {\n  margin-top: 1.25rem;\n  margin-bottom: 1.25rem;\n  background-color: white;\n  padding: 1rem;\n  border-radius: 4px;\n  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);\n  overflow-x: auto;\n  width: fit-content;\n  overflow-x: auto;\n}\n\n.scrollable-table {\n  /* width: 720px; */\n  max-width: 100%;\n}\n\n.table {\n  color: black;\n  min-width: 400px;\n}\n\n.table th,\n.table td {\n  color: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
