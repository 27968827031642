// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart-container {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  width: -moz-fit-content;
  width: fit-content;
  max-width: 100%;
  padding: 1rem;
  overflow-x: auto;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Chart.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,sBAAsB;EACtB,uBAAuB;EACvB,kBAAkB;EAClB,sCAAsC;EACtC,uBAAkB;EAAlB,kBAAkB;EAClB,eAAe;EACf,aAAa;EACb,gBAAgB;AAClB","sourcesContent":[".chart-container {\n  margin-top: 1.25rem;\n  margin-bottom: 1.25rem;\n  background-color: white;\n  border-radius: 4px;\n  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);\n  width: fit-content;\n  max-width: 100%;\n  padding: 1rem;\n  overflow-x: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
