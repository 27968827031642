import { Box, CircularProgress, Stack } from '@mui/material';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useRef, useState } from 'react';
import ScrollToBottom, { useScrollToBottom } from 'react-scroll-to-bottom';
import { v4 as uuidv4 } from 'uuid';

import {
  ChatInputForm,
  ChatMessage,
  PageLayout,
  ResponseFailedError,
} from '../../Components';
import CustomDataSetUpload from '../../Components/CustomDataSetUpload';
import GetStartedArrow from '../../Components/GetStartedArrow';
import API_URL from '../../config';
import useOnScreen from '../../Hooks/useOnScreen';
import { useBranding } from '../../Utils/BrandingContext';
import LandingPage from '../LandingPage';
import { arrayToBulletPoints } from './utils';

function MainPage({ loggedInUser }) {
  const [responseFailed, setResponseFailed] = useState(false);
  const [input, setInput] = useState('');
  const [chatLog, setChatLog] = useState([]);
  const [isLoading, setIsLoading] = useState(null);
  const [chatId] = useState(uuidv4());
  const [files, setFiles] = useState([]);
  const [knowledgeBaseId, setKnowledgeBaseId] = useState(null);
  const [error, setError] = useState(null);
  const [mode, setMode] = useState(
    loggedInUser.canUploadCustomDataSet ? 'INIT' : 'CHAT',
  ); // INIT, CHAT, FILE_UPLOAD
  const [showSql] = useState(loggedInUser.isDev);
  const bottomChatMessageRef = useRef(null);
  const isBottomChatMessageVisible = useOnScreen(bottomChatMessageRef);
  const { branding } = useBranding();
  const scrollToBottom = useScrollToBottom();

  if (!isBottomChatMessageVisible) {
    scrollToBottom({
      behavior: 'smooth',
    });
  }

  useEffect(() => {
    const handleResize = debounce(() => {}, 200);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const clearInput = useCallback(() => {
    setInput('');
  }, []);

  const getSummary = useCallback(async () => {
    try {
      const response = await fetch(`${API_URL}/chat/${chatId}/summarize_data`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${loggedInUser.accessToken}`,
        },
      });
      if (!response.ok) {
        throw new Error(
          `Failed to fetch summary data: ${response.status} ${response.statusText}`,
        );
      }
      const summary = await response.json();

      return summary;
    } catch (err) {
      console.error('Unable to get initial data summary: ', err);
      throw err;
    }
  }, [chatId, loggedInUser.accessToken]);

  const initCoPilot = useCallback(async () => {
    if (mode === 'CHAT' && chatLog?.length === 0) {
      let summary = {};
      let questions = '';
      let message = '';

      try {
        setIsLoading(true);

        summary = await getSummary();
        questions = Array.isArray(summary?.suggestions)
          ? arrayToBulletPoints(summary?.suggestions)
          : '';
      } catch (err) {
        console.error('There was a problem fetching the summary: ', err);
      } finally {
        if (summary?.summary) {
          message = `${branding.texts.init_prompt}\n\n${summary.summary}\n\nExamples of questions that can be answered using this data include: \n${questions}\n\n
            ${branding.texts.question_disclaimer}\n\n${branding.texts.init_prompt_2}`;
        } else if (files && files[0] && files[0].name) {
          message = `${branding.texts.init_prompt}\n\nYou’re data set ${files[0].name} has been uploaded and is ready for analysis.\n\nA good starting question could be:  
        • Please give a brief overview of what this data set is, it's purpose, and what are some sample questions to ask you to gain insights from the data?\n\nHow can I help you?`;
        } else {
          message = `${branding.texts.init_prompt}\n\n${branding.texts.init_prompt_2}`;
        }
        setIsLoading(false);
      }

      setChatLog([
        {
          user: 'gpt',
          message,
          data: [],
          resultType: 'text',
        },
      ]);
    }
  }, [
    chatLog?.length,
    files,
    getSummary,
    mode,
    branding.texts.init_prompt,
    branding.texts.init_prompt_2,
    branding.texts.question_disclaimer,
  ]);

  useEffect(() => {
    initCoPilot();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();

      const chatLogNew = [
        ...chatLog,
        { user: 'me', message: `${input}`, resultType: 'text' },
      ];

      clearInput();
      setChatLog(chatLogNew);
      setIsLoading(true);
      setResponseFailed(false);

      const response = await fetch(`${API_URL}/chat/${chatId}/converse`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${loggedInUser.accessToken}`,
        },
        body: JSON.stringify({
          message: input,
        }),
      }).catch(() => {
        setResponseFailed(true);
      });

      setIsLoading(false);

      if (!response.ok) {
        setResponseFailed(true);
        return;
      }

      const responseJson = await response.json();
      setChatLog(prevChat => [
        ...prevChat,
        {
          user: 'gpt',
          data: JSON.parse(responseJson.data),
          resultType: responseJson.resultType,
          message: responseJson.message,
          sql: responseJson.sql,
        },
      ]);
    },
    [chatId, chatLog, clearInput, input, loggedInUser.accessToken],
  );

  const handleFileChange = useCallback(f => {
    const selectedFile = f[0];
    if (selectedFile) {
      if (selectedFile.size > 25 * 1024 * 1024) {
        setError('File size exceeds 25MB');
      } else {
        setFiles(f);
        setError(null);
      }
    }
  }, []);

  const handleKnowledgeBaseChange = useCallback(event => {
    setKnowledgeBaseId(event.target.value);
  }, []);

  const handleUpload = useCallback(async () => {
    if (files[0]) {
      const formData = new FormData();
      formData.append('file', files[0]);

      try {
        const response = await fetch(
          `${API_URL}/chat/${chatId}/upload?knowledgeBaseId=${
            knowledgeBaseId ?? ''
          }`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${loggedInUser.accessToken}`,
            },
            body: formData,
          },
        );
        setMode('CHAT');

        if (response.status !== 200) {
          setError('Could not upload file');
        } else {
          setMode('CHAT');
        }
      } catch (err) {
        setError('Could not upload file');
      }
    }
  }, [chatId, files, loggedInUser.accessToken, knowledgeBaseId]);

  return (
    <PageLayout>
      <Stack
        alignItems="center"
        component={ScrollToBottom}
        flexGrow={1}
        height="100%"
        id="main-page-container"
        paddingX="4rem"
        sx={{
          overflowY: 'auto',
        }}
        width="100%"
      >
        {mode !== 'INIT' && mode === 'FILE_UPLOAD' && (
          <CustomDataSetUpload
            error={error}
            files={files}
            handleFileChange={handleFileChange}
            handleKnowledgeBaseChange={handleKnowledgeBaseChange}
            handleUpload={handleUpload}
            sx={{
              marginTop: '2rem',
            }}
          />
        )}
        {chatLog.length === 0 && mode === 'INIT' && (
          <LandingPage setMode={setMode} />
        )}
        {chatLog.map((message, index) => (
          <ChatMessage key={index} message={message} showSql={showSql} />
        ))}
        {mode === 'CHAT' && chatLog.length === 1 && <GetStartedArrow />}
        {isLoading && (
          <Box
            alignItems="center"
            display="flex"
            justifyContent="center"
            padding={1}
            width="100%"
          >
            <CircularProgress sx={{ color: '#b3befe' }} />
          </Box>
        )}
        {responseFailed && <ResponseFailedError />}
        <div ref={bottomChatMessageRef} />
      </Stack>

      {mode !== 'INIT' && mode === 'CHAT' && (
        <ChatInputForm
          handleSubmit={e => handleSubmit(e)}
          input={input}
          isLoading={isLoading}
          setInput={setInput}
        />
      )}
    </PageLayout>
  );
}

export default MainPage;
