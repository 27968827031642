import { Box, Container, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { CONTAINER_BG_COLOR, CONTAINER_SIZE } from '../Themes/style';
import { useBranding } from '../Utils/BrandingContext';
import Header from './Header';

function PageLayout({ children }) {
  const [appBarHeight, setAppBarHeight] = useState(0);

  useEffect(() => {
    setAppBarHeight(document.querySelector('header')?.offsetHeight ?? 0);
  }, []);

  const { branding } = useBranding();

  return (
    <>
      {branding.shouldShowHeader && <Header />}
      <Box
        bgcolor="#D9D9D9"
        component="main"
        display="flex"
        flexGrow={1}
        position="relative"
      >
        <Container
          component={Paper}
          maxWidth={CONTAINER_SIZE}
          sx={{
            background: { CONTAINER_BG_COLOR },
            boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.15)',
            height: `calc(100vh - ${appBarHeight}px)`,
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            overflowY: 'auto',
          }}
          disableGutters
        >
          {children}
        </Container>
      </Box>
    </>
  );
}

export default PageLayout;
