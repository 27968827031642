import { SvgIcon } from '@mui/material';
import React, { memo } from 'react';

const SubmitButtonSVG = memo(() => (
  <SvgIcon id="submit-button-svg">
    <svg
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="send">
        <g id="Vector">
          <path
            d="M0.61543 6.20225C0.217103 6.2132 0.0148614 6.68615 0.282111 6.98172L7.86341 15.3664L30.5202 5.37976L0.61543 6.20225Z"
            fill="#533EED"
          />
          <path
            d="M31.4668 6.01256L10.1132 19.4664L12.8857 30.6857C12.9813 31.0725 13.4865 31.1693 13.7183 30.8452L31.4153 6.10392C31.4364 6.07438 31.4535 6.04377 31.4668 6.01256Z"
            fill="#533EED"
          />
        </g>
      </g>
    </svg>
  </SvgIcon>
));
SubmitButtonSVG.displayName = 'SubmitButtonSVG';

export default SubmitButtonSVG;
