import { Box, Typography } from '@mui/material';
import { motion } from 'framer-motion';

import ArrowDown from '../Assets/Images/ArrowDown.svg';
import { useBranding } from '../Utils/BrandingContext';

const MotionBox = motion(Box);

export default function GetStartedArrow() {
  const { branding } = useBranding();

  return (
    <MotionBox
      alignItems="center"
      animate={{ opacity: 1 }}
      display="flex"
      initial={{ opacity: 0 }}
      justifyContent="center"
      marginTop="2rem"
      sx={{
        gap: '2rem',
      }}
      transition={{ duration: 0.5 }}
      width="100%"
    >
      <Typography color="#012A75" fontWeight="bold" variant="body1">
        {branding.texts.arrow_down}
      </Typography>
      <img alt="down arrow" src={ArrowDown} style={{ maxWidth: '100%' }} />
    </MotionBox>
  );
}
